import React from 'react'
import './style.css'
function Footer() {
  return (
    <div className='footer'>
        <div className='container'>
            <div className='copyRight'>
                <p>أكاديمية الصفوة</p>
                <p>جميع الحقوق محفوظة 2023</p>
            </div>
        </div>
    </div>
  )
}

export default Footer